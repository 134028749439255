/**
 * @flow
 * @relayHash 10203bfe4b2cf705f4aacf29420766c9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EscQueryVariables = {|
  type?: ?string
|};
export type EscQueryResponse = {|
  +files: ?$ReadOnlyArray<?{|
    +id: ?string,
    +filename: ?string,
    +uploadDate: ?string,
  |}>
|};
export type EscQuery = {|
  variables: EscQueryVariables,
  response: EscQueryResponse,
|};
*/


/*
query EscQuery(
  $type: String
) {
  files(type: $type) {
    id
    filename
    uploadDate
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "type",
    "type": "String"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "type",
        "variableName": "type"
      }
    ],
    "concreteType": "Files",
    "kind": "LinkedField",
    "name": "files",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "filename",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uploadDate",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EscQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EscQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "10203bfe4b2cf705f4aacf29420766c9",
    "metadata": {},
    "name": "EscQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '40b07017a166a17a9b1eff0898aef02e';

module.exports = node;
