import * as R from 'ramda';
import React, { useState } from 'react';
import { useBuild } from './hooks/useBuild';
import { connect } from 'react-redux';
import { graphql, QueryRenderer } from 'react-relay';
import { Output } from '../../components/index';
import environment from '../../Environment';
import FilesList from './components/FilesList';
import { commandBuildFile, resetBuildStatus } from '../../redux_actions/command';
import FileHeader from './components/FilesHeader';
import { Col, Select } from 'antd';
import Filter from './components/Filter';
import moment from 'moment';
import { rolesList } from '../../data/rolesList';
import FilterCcn from './filters/FilterCcn';
import FilterUpdateDatePeriod from './filters/FilterUpdateDatePeriod';
import FilterStatus from './filters/FilterStatus';
import FilterSelect from './filters/FilterSelect';
import FilterSales from './filters/FilterSales';

const type = 'traceoffer';

const TraceOfferQuery = graphql`
  query TraceOfferQuery($type: String) {
    files(type: $type) {
      id
      filename
      uploadDate
    }
    ccns {
      id
      shortTitle
    }
    users {
      id
      extra {
        firstname
        lastname
      }
    }
    data {
      managementCenters {
        code
        name
      }
      regions {
        code
        title
      }
    }
  }
`;

const formatDate = date => {
  if (R.isNil(date)) {
    return undefined;
  }

  return moment(date).format('YYYY-MM-DD');
};
const toBoolean = R.cond([
  [R.equals('yes'), R.always(true)],
  [R.equals('no'), R.always(false)],
  [R.T, R.always(undefined)]
]);

const yesNo = [
  { label: 'Oui', value: 'yes' },
  { label: 'Non', value: 'no' }
];

const healthPrev = [
  { label: 'Santé', value: 'SANTE' },
  { label: 'Prévoyance', value: 'PREVOYANCE' }
];

const TraceOfferFilters = ({ filters, setFilters, ccns, users, managementCenters, regions }) => {
  const updateFilter = (key, value) => setFilters(R.assoc(key, value));
  const getFilter = key => R.prop(key, filters);
  const selectFilters = key => ({
    style: { width: '100%' },
    value: getFilter(key),
    onChange: v => updateFilter(key, v),
    allowClear: true
  });

  return <Col style={{ width: '600px', margin: '20px auto' }}>
    <FilterCcn
      ccns={ccns}
      value={getFilter('ccnList')}
      onChange={v => updateFilter('ccnList', v)}
      multi
    />

    <FilterStatus
      value={getFilter('statusList')}
      onChange={v => updateFilter('statusList', v)}
      multi
    />

    <Filter
      label="Rôle"
    >
      <Select
        placeholder="Sélectionner un rôle"
        {...selectFilters('role')}
      >
        {R.map(({ title, name }) => {
          return <Select.Option
            key={name}
            value={name}
          >
            {title}
          </Select.Option>;
        })(rolesList)}
      </Select>
    </Filter>

    <FilterSelect
      label="Type d'affaire"
      placeholder="Sélectionner un type d'affaire"
      {...selectFilters('typesList')}
      multi
      options={[
        { label: 'Affaire nouvelle', value: 'newContract' },
        { label: 'Avenant', value: 'amendment' },
        { label: 'Suite économique', value: 'relatedContract' },
        { label: 'Duplicata', value: 'duplicata' },
        { label: 'SPID Client', value: 'SPID_CLIENT' }
      ]}
    />

    <Filter
      label="Type d'offre"
    >
      <Select
        placeholder="Sélectionner un type d'offre"
        showSearch
        {...selectFilters('offerType')}
      >
        {R.map(({ label, value }) => {
          return <Select.Option
            key={value}
            value={value}
          >
            {label}
          </Select.Option>;
        })(healthPrev)}
      </Select>
    </Filter>

    <FilterSales
      {...selectFilters('sales')}
    />

    <Filter
      label="Nom créateur"
    >
      <Select
        placeholder="Sélectionner un utilisateur"
        {...selectFilters('user')}
        showSearch
      >
        {R.map(({ id, extra }) => {
          return <Select.Option
            key={id}
            value={id}
          >
            {R.propOr('', 'firstname', extra)} {R.propOr('', 'lastname', extra)}
          </Select.Option>;
        })(users)}
      </Select>
    </Filter>

    <Filter
      label="Questionnaire médical"
    >
      <Select
        placeholder="Questionnaire médical"
        {...selectFilters('medicalCheck')}
      >
        {R.map(({ label, value }) => {
          return <Select.Option
            key={value}
            value={value}
          >
            {label}
          </Select.Option>;
        })(yesNo)}
      </Select>
    </Filter>

    <Filter
      label="Majoration"
    >
      <Select
        placeholder="Majoration"
        {...selectFilters('medicalCheckOverprice')}
      >
        {R.map(({ label, value }) => {
          return <Select.Option
            key={value}
            value={value}
          >
            {label}
          </Select.Option>;
        })(yesNo)}
      </Select>
    </Filter>

    <Filter
      label="Centre de gestion"
    >
      <Select
        placeholder="Sélectionner un centre de gestion"
        {...selectFilters('managementCentersList')}
        mode="multiple"
      >
        {R.map(({ code, name }) => {
          return <Select.Option
            key={code}
            value={code}
          >
            {name}
          </Select.Option>;
        })(managementCenters)}
      </Select>
    </Filter>

    <Filter
      label="Région"
    >
      <Select
        placeholder="Sélectionner une région"
        {...selectFilters('regionsList')}
        mode="multiple"
      >
        {R.map(({ code, title }) => {
          return <Select.Option
            key={code}
            value={code}
          >
            {title}
          </Select.Option>;
        })(regions)}
      </Select>
    </Filter>

    <FilterUpdateDatePeriod
      fromValue={getFilter('fromUpdateDate')}
      toValue={getFilter('toUpdateDate')}
      onChange={updateFilter}
    />
  </Col>;
};

const TraceOffer = ({
  traceOfferBuildingStatus,
  buildTraceOffer,
  resetBuild
}) => {
  const { reload, onReload } = useBuild(traceOfferBuildingStatus, resetBuild);

  const [filters, setFilters] = useState({
    ccn: undefined,
    status: undefined,
    type: undefined,
    offerType: undefined,
    role: undefined,
    sales: undefined,
    user: undefined,
    medicalCheck: undefined,
    medicalCheckOverprice: undefined,
    managementCenter: undefined,
    region: undefined,
    fromUpdateDate: undefined,
    toUpdateDate: undefined
  });

  const formatFilters = R.compose(
    R.over(R.lensProp('fromUpdateDate'), formatDate),
    R.over(R.lensProp('toUpdateDate'), formatDate),
    R.over(R.lensProp('medicalCheck'), toBoolean),
    R.over(R.lensProp('medicalCheckOverprice'), toBoolean)
  );

  return <Output>
    <QueryRenderer
      environment={environment}
      query={TraceOfferQuery}
      variables={{
        type,
        reload
      }}
      render={({ error, props }) => {
        if (error) {
          return <div>{error}</div>;
        }

        return <>
          <TraceOfferFilters
            filters={filters}
            setFilters={setFilters}
            ccns={R.propOr([], 'ccns', props)}
            users={R.propOr([], 'users', props)}
            managementCenters={R.pathOr([], ['data', 'managementCenters'], props)}
            regions={R.pathOr([], ['data', 'regions'], props)}
          />

          <FileHeader
            label={'Générer un fichier de suivi des offres'}
            onReload={onReload}
            onBuild={() => buildTraceOffer(formatFilters(filters))}
            status={traceOfferBuildingStatus}
          />

          <FilesList
            files={R.path(['files'])(props)}
            type={'Suivi des offres'}
            exts={['xlsx']}
          />
        </>;
      }}
    />
  </Output>;
};

export default connect(
  (state) => {
    return {
      traceOfferBuildingStatus: R.pathOr('', ['command', 'traceofferBuildingStatus'])(state)
    };
  },
  (dispatch) => ({
    buildTraceOffer: (filters) => dispatch(commandBuildFile(type, filters)),
    resetBuild: () => dispatch(resetBuildStatus(type))
  })
)(TraceOffer);
